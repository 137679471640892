import React, { useState, useEffect, useRef, useCallback } from 'react';
import ReactMarkdown from 'react-markdown';
import Button from './Button';
import PropTypes from 'prop-types';
import ProgressBar from 'react-bootstrap/ProgressBar';
import logoGreeting from './logo_greeting.png';
import FileList from './FileList';
import Icon from './icon';
import { useNavigate } from 'react-router-dom';


const ChatWindow = ({ 
  messages, 
  onSendMessage, 
  onFileUpload,
  onRemoveFile,
  uploadedFiles,
  onMediaUpload,
  onGenerateReport, 
  onAbortGeneration,
  isProcessing, 
  openReportInNewTab,
  onDownload,
  generationProgress,
  isOpen,
  uploadedMediaFile,
  setUploadedMediaFile,
  isNewChat
}) => {
  const navigate = useNavigate();
  const [input, setInput] = useState('');
  const textareaRef = useRef(null);
  const chatContainerRef = useRef(null);
  const fileInputRef = useRef(null);
  const mediaInputRef = useRef(null);

  const adjustTextareaHeight = useCallback(() => {
    if (textareaRef.current) {
      // Reset to initial height
      textareaRef.current.style.height = '40px';
      
      // Calculate new height
      const scrollHeight = textareaRef.current.scrollHeight;
      
      // Set new height, but not less than initial height
      textareaRef.current.style.height = `${Math.max(40, Math.min(scrollHeight, 400))}px`;
    }
  }, []);

  
  const greetingMessage = (
    <div className="greeting-message">
      <img src={logoGreeting} alt="NEURIX Logo" className="greeting-logo" />
      <div className="greeting-options">
        <div 
          className="greeting-option-left clickable" 
          onClick={() => fileInputRef.current.click()}
        >
          <Icon name="paperclip" className="greeting-icon" />
          <h3>Arztbriefe erstellen</h3>
          <p>Laden Sie ein Dokument hoch und klicken Sie auf "Generieren"</p>
        </div>
        <div 
          className="greeting-option-middle clickable"
          onClick={() => textareaRef.current?.focus()}
        >
          <Icon name="doctorMessage" className="greeting-icon2" />
          <h3>Medizinischer Chat</h3>
          <p>Stellen Sie medizinische Fragen im Chatfenster</p>
        </div>
        <div 
          className="greeting-option-right clickable" 
          onClick={() => mediaInputRef.current.click()}
        >
          <Icon name="media" className="greeting-icon" />
          <h3>Bilder & Videos analysieren</h3>
          <p>Laden Sie medizinische Bilder oder Videos zur Analyse hoch</p>
        </div>
      </div>
    </div>
  );


  const handleInputChange = (e) => {
    setInput(e.target.value);
    adjustTextareaHeight();
  };

  useEffect(() => {
    adjustTextareaHeight();
  }, [adjustTextareaHeight, input]);

  useEffect(() => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
    }
  }, [messages]);

  const handleSend = useCallback(() => {
    if (input.trim() || uploadedMediaFile) {
      onSendMessage(input, uploadedMediaFile);
      setInput('');
      setUploadedMediaFile(null);
      if (textareaRef.current) {
        textareaRef.current.style.height = '40px';
      }
    }
  }, [input, uploadedMediaFile, onSendMessage, setUploadedMediaFile]);

  const renderGenerateButton = () => {
    if (isProcessing) {
      return (
        <Button 
          onClick={onAbortGeneration} 
          className="abort-button"
          icon="stop"
          tooltip="Generierung abbrechen"
        >
          Abbrechen
        </Button>
      );
    } else {
      return (
        <Button 
          onClick={onGenerateReport} 
          disabled={isProcessing}
          className="orange"
          tooltip="Arztbrief hier generieren"
        >
          Generieren
        </Button>
      );
    }
  };

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      onFileUpload(file);
      fileInputRef.current.value = '';
    }
  };

  const handleMediaUpload = useCallback((event) => {
    const file = event.target.files[0];
    if (file) {
      onMediaUpload(file);
      mediaInputRef.current.value = '';
    }
  }, [onMediaUpload]);

  const renderMediaPreview = useCallback(() => {
    if (!uploadedMediaFile) return null;

    const isImage = uploadedMediaFile.type.startsWith('image/');
    const isVideo = uploadedMediaFile.type.startsWith('video/');

    if (isImage) {
      return (
        <div className="media-preview">
          <img src={URL.createObjectURL(uploadedMediaFile)} alt="Preview" style={{maxWidth: '100px', maxHeight: '100px'}} />
          <button onClick={() => setUploadedMediaFile(null)}>X</button>
        </div>
      );
    } else if (isVideo) {
      return (
        <div className="media-preview">
          <video src={URL.createObjectURL(uploadedMediaFile)} style={{maxWidth: '100px', maxHeight: '100px'}} controls />
          <button onClick={() => setUploadedMediaFile(null)}>X</button>
        </div>
      );
    }
  }, [uploadedMediaFile, setUploadedMediaFile]);

  const renderMessage = useCallback((msg) => {
    const isImage = (file) => file && file.type && file.type.startsWith('image/');
    const isVideo = (file) => file && file.type && file.type.startsWith('video/');

    const getMediaSrc = (mediaFile, mediaUrl) => {
      if (mediaFile instanceof File) {
        return URL.createObjectURL(mediaFile);
      }
      return mediaUrl || '';
    };

    return (
      <>
        <ReactMarkdown
          children={msg.text}
          components={{
            a: ({node, ...props}) => (
              <a 
                onClick={(e) => {
                  e.preventDefault();
                  if (props.href && props.href.includes('view-report')) {
                    openReportInNewTab(props.href);
                  } else if (props.href && props.href.includes('download')) {
                    onDownload(props.href);
                  }
                }} 
                {...props} 
                style={{color: '#58a6ff', textDecoration: 'underline', cursor: 'pointer'}}
              >
                {props.children}
              </a>
            )
          }}
        />
        {(msg.mediaFile || msg.mediaUrl) && (
          <div className="message-media-preview">
            {isImage(msg.mediaFile) || (msg.mediaType && msg.mediaType.startsWith('image/')) ? (
              <img src={getMediaSrc(msg.mediaFile, msg.mediaUrl)} alt="Uploaded media" style={{maxWidth: '200px', maxHeight: '200px'}} />
            ) : isVideo(msg.mediaFile) || (msg.mediaType && msg.mediaType.startsWith('video/')) ? (
              <video src={getMediaSrc(msg.mediaFile, msg.mediaUrl)} style={{maxWidth: '200px', maxHeight: '200px'}} controls />
            ) : (
              <p>File: {msg.mediaFile ? msg.mediaFile.name : 'Uploaded file'}</p>
            )}
          </div>
        )}
      </>
    );
  }, [openReportInNewTab, onDownload]);

  return (
    <>
      <div className="chat-container" ref={chatContainerRef}>
        {isNewChat ? (
          greetingMessage
        ) : (
          // Existing message rendering logic
          Array.isArray(messages) && messages.map((msg, index) => (
            <div key={index} className={`message ${msg.sender}`}>
              {renderMessage(msg)}
            </div>
          ))
        )}
        {generationProgress && (
          <div className="progress-bar-container">
            <ProgressBar 
              now={generationProgress.current / generationProgress.total * 100} 
              label={`${Math.round(generationProgress.current / generationProgress.total * 100)}%`}
              style={{
                height: '24px',
                backgroundColor: '#e9ecef',
                borderRadius: '0.25rem',
              }}
            />
            <p style={{ marginTop: '10px', fontSize: '14px', color: '#ffffff' }}>
              Generiere Abschnitt {generationProgress.sectionName}
            </p>
            <p style={{ marginTop: '5px', fontSize: '14px', color: '#ffffff' }}>
              Abschnitt {generationProgress.current} von {generationProgress.total}
            </p>
          </div>
        )}
      </div>
      <div className={`bottom-controls ${isOpen ? 'sidebar-open' : ''}`}>
        <div className="media-preview-container">
          {renderMediaPreview()}
        </div>
        <div className="document-preview-container">
          {uploadedFiles.length > 0 && !isProcessing && (
            <FileList 
              files={uploadedFiles} 
              onRemoveFile={onRemoveFile}
              compact={true}
            />
          )}
        </div>
        <div className="input-sections">
          <div className="chat-controls">
            <div className="input-container">
              <textarea
                ref={textareaRef}
                value={input}
                onChange={handleInputChange}
                onKeyPress={(e) => e.key === 'Enter' && !e.shiftKey && (e.preventDefault(), handleSend())}
                placeholder="Nachricht ..."
                disabled={isProcessing}
                className="auto-resize-textarea"
              />
              <div className="button-group">
                <Button 
                  onClick={() => mediaInputRef.current.click()} 
                  disabled={isProcessing}
                  icon="media"
                  tooltip="Foto oder Video hochladen"
                />
                <input
                  ref={mediaInputRef}
                  type="file"
                  style={{ display: 'none' }}
                  onChange={handleMediaUpload}
                  accept="image/*,video/*"
                />
                <Button 
                  onClick={handleSend} 
                  disabled={isProcessing} 
                  className="handleSend"
                  icon="arrowUp"
                  tooltip="Nachricht an KI"
                />
              </div>
            </div>
          </div>
          <div className="document-controls">
            <Button 
              onClick={() => fileInputRef.current.click()} 
              disabled={isProcessing}
              icon="paperclip"
              tooltip="Dokument hochladen"
            />
            <input
              ref={fileInputRef}
              type="file"
              style={{ display: 'none' }}
              onChange={handleFileUpload}
              accept=".txt,.pdf,.md,.docx"
            />
            {renderGenerateButton()}
          </div>
        </div>
      </div>
      {isProcessing && (
        <div className="processing-message">
          Generiere Arztbrief ...
        </div>
      )}
      <div className={`permanent-disclaimer-container ${isOpen ? 'sidebar-open' : ''}`}>
        <div className="permanent-disclaimer">
          Die von der KI erstellten Diagnosen können fehlerhaft sein und stellen keine medizinische Beratung dar.
        </div>
        <div className="bottom-right-icon-container" onClick={() => navigate('/privacy')}>
          <Icon name="question" className="bottom-right-icon" />
        </div>
      </div>
    </>
  );
};

ChatWindow.propTypes = {
  messages: PropTypes.array,
  onSendMessage: PropTypes.func.isRequired,
  onFileUpload: PropTypes.func.isRequired,
  onGenerateReport: PropTypes.func.isRequired,
  isProcessing: PropTypes.bool.isRequired,
  openReportInNewTab: PropTypes.func.isRequired,
  onDownload: PropTypes.func.isRequired,
  onMediaUpload: PropTypes.func.isRequired,
  uploadedMediaFile: PropTypes.object,
  setUploadedMediaFile: PropTypes.func.isRequired,
  generationProgress: PropTypes.shape({
    current: PropTypes.number,
    total: PropTypes.number,
    sectionName: PropTypes.string
  }),
  onAbortGeneration: PropTypes.func.isRequired
};

ChatWindow.defaultProps = {
  messages: [],
  generationProgress: null
};

export default ChatWindow;